










import { alertToast } from '@/utils';
import { Component, Vue, Prop, Emit, Mixins, Watch, Inject, Provide } from 'vue-property-decorator';

@Component({
  name: 'Navigation',
  components: {}
})
export default class App extends Vue {
  map = null;
  position = null;
  startPosition = null;

  get title() {
    return this.$route.meta.title || '地图导航';
  }

  get uid() {
    return 'amap_' + (this as any)._uid;
  }
  // 初始化地图
  initMap() {
    var AMap = (window as any).AMap;
    if (AMap) {
      var map = new AMap.Map(this.uid, {
        zoom: 13,
        // center: this.position,
        resizeEnable: true
        // mapStyle: 'amap://styles/whitesmoke'
      });

      this.map = map;

      this.getStartPosition(map)
        .then((startPosition) => {
          const endPistion = this.position.map((item) => Number(item));
          this.drawMap(map, startPosition, endPistion);
        })
        .catch(() => {
          alertToast('获取当前定位失败，无法导航');
        });
    } else {
      alertToast('地图加载失败，请稍后再试');
    }
  }
  drawMap(map, startPosition, endPosition) {
    const [slng, slat] = startPosition;
    const [elng, elat] = endPosition;
    console.log(startPosition, endPosition);
    var AMap = (window as any).AMap;
    try {
      if (AMap) {
        var driving = new AMap.Driving({
          map: map
          // panel: 'panel'
        });
        // 根据起终点经纬度规划驾车导航路线
        driving.search(new AMap.LngLat(slng, slat), new AMap.LngLat(elng, elat), function (status, result) {
          // result 即是对应的驾车导航信息，相关数据结构文档请参考  https://lbs.amap.com/api/javascript-api/reference/route-search#m_DrivingResult
          if (status !== 'complete') {
            console.log('result', result);

            alertToast('获取导航数据失败：' + result);
          }
        });
      }
    } catch (e) {
      console.log('try catch:', e);
    }
  }

  getStartPosition(map) {
    return new Promise((resolve, reject) => {
      var AMap = (window as any).AMap;
      if (AMap) {
        AMap.plugin('AMap.Geolocation', function () {
          var geolocation = new AMap.Geolocation({
            enableHighAccuracy: true, // 是否使用高精度定位，默认:true
            timeout: 10000, // 超过10秒后停止定位，默认：5s
            buttonPosition: 'RB', // 定位按钮的停靠位置
            buttonOffset: new AMap.Pixel(10, 20), // 定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
            zoomToAccuracy: true // 定位成功后是否自动调整地图视野到定位点
          });
          map.addControl(geolocation);
          geolocation.getCurrentPosition(function (status, result) {
            if (status == 'complete') {
              const { lng, lat } = result.position;
              resolve([lng, lat]);
            } else {
              reject('获取定位失败');
            }
          });
        });
      }
    });
  }

  created() {}
  mounted() {
    const { lon, lat } = this.$route.query as any;

    if (!lon || !lat) {
      alertToast('无效的位置信息');
    } else {
      this.position = [lon, lat];
    }

    if (this.position && this.position.length == 2) {
      this.$nextTick(() => {
        this.initMap();
      });
    }
  }
}
